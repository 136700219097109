import { useCallback, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Select } from "formik-antd";
import { ColumnType, TextType, GroupingType } from "./mappingTypes";
import { useTranslation } from "react-i18next";
import { newMappingSchema } from "/app/src/schemas/apps/dataPush/newMappingSchema";
import { Integration, ReportColumnType, Mapping } from "/app/src/models";
import { Error } from "/app/src/types";

/**
 * Component for creating a new mapping. Maps the mapping type to the correct component.
 */
function NewMappingTypes({
  isThemed,
  type,
  columnTypes,
  dirty,
  isValid,
}: {
  isThemed: boolean;
  type: string;
  columnTypes: ReportColumnType[];
  dirty: boolean;
  isValid: boolean;
}) {
  return (
    <>
      {type === "column" && (
        <ColumnType
          isThemed={isThemed}
          columnTypes={columnTypes}
          dirty={dirty}
          isValid={isValid}
        />
      )}
      {type === "text" && <TextType dirty={dirty} isValid={isValid} />}
      {type === "grouping" && <GroupingType dirty={dirty} />}
    </>
  );
}

/**
 * Format the form value keys to match the API Model. Some complex logic
 * is required to handle the different types of columns and text.
 */
export function formatForm(values: FormValues): Mapping {
  let columnType;
  let value;
  //columns selected from a report will not be an array. Theme columns
  //will be an array(id stored in the 1 index)
  if (Array.isArray(values.columnTypeId)) {
    columnType = values.columnTypeId[1];
    value = values.columnTypeId[0];
  } else {
    value = values?.value;
    columnType = values?.columnTypeId;
  }
  const ret = {
    ...(values?.parentMappingId && {
      parentMappingId: values?.parentMappingId,
    }),
    key: values?.key,
    value,
    type: values?.type,
  };
  if (values.type === "column") {
    ret["columnTypeId"] = columnType;
  }
  if (values.type === "text") {
    ret.value = values.value;
  }
  return ret;
}
interface FormValues {
  key: string | undefined;
  value: string | undefined;
  columnTypeId: number | [string | undefined, number | undefined] | undefined;
  type: string | undefined;
  parentMappingId?: number;
}

/**
 * Component for creating a new mapping.
 */
export default function NewMapping({
  integration,
  addMapping,
  columnTypes,
  parentId = undefined,
}: {
  integration?: Integration;
  addMapping: (mapping: Mapping) => Promise<Error | { mapping: Mapping }>;
  columnTypes: ReportColumnType[];
  parentId?: number | undefined;
}) {
  const { t } = useTranslation();
  const [type, setType] = useState("");

  const changeType = useCallback((value: string) => {
    setType(value);
  }, []);

  /**
   * Component for selecting the mapping type.
   */
  const selectMappingTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ setFieldValue, dirty, isValid }) => (
        <Form>
          <Row justify="start" gutter={16}>
            <Col span={6}>
              <Form.Item name={"type"}>
                <Select
                  name={"type"}
                  size="large"
                  placeholder={t("translation:select_mapping_type")}
                  onChange={(value) => {
                    changeType(value);
                    setFieldValue("value", "");
                    setFieldValue("key", "");
                  }}
                >
                  <Select.Option value={"column"} id={1}>
                    {"Column"}
                  </Select.Option>
                  <Select.Option value={"text"} id={1}>
                    {"Text"}
                  </Select.Option>
                  <Select.Option value={"grouping"} id={1}>
                    {"Grouping"}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={18}>
              {type !== "" && (
                <NewMappingTypes
                  isThemed={Boolean(integration?.baseTable)}
                  type={type}
                  columnTypes={columnTypes}
                  dirty={dirty}
                  isValid={isValid}
                />
              )}
            </Col>
          </Row>
        </Form>
      ),
      [changeType, columnTypes, integration?.baseTable, t, type],
    );
  const initValues: FormValues = {
    key: undefined,
    value: undefined,
    type: undefined,
    columnTypeId: undefined,
  };

  /**
   * Handle the form submit. Format the form values and call the addMapping
   * function.
   */
  const handleSubmit = useCallback(
    async (values, actions) => {
      values["parentMappingId"] = parentId;
      const data = formatForm(values);
      data["integrationId"] = integration.id;

      await addMapping(data).finally(() => {
        actions.resetForm();
      });
    },
    [addMapping, integration.id, parentId],
  );
  return (
    <Formik
      component={selectMappingTypeForm}
      initialValues={initValues}
      validationSchema={newMappingSchema}
      onSubmit={handleSubmit}
    />
  );
}
