import React, { useState, useEffect, useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";

import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { App as AppType, Setting as SettingType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";

interface FormValues {
  hue_app_id: string | undefined;
  hue_client_id: string | undefined;
  hue_client_secret: string | undefined;
}

export default function Hue({ app }: { app: AppType }) {
  const { t } = useTranslation();
  const [clientId, setClientId] = useState<SettingType>({ value: "" });
  const [clientSecret, setClientSecret] = useState<SettingType>({ value: "" });
  const [appId, setAppId] = useState<SettingType>({ value: "" });

  //checks the settings service response to see if
  //an empty array was returned
  //if not empty, return the object,
  //else return an empty string
  function checkResponse(response: { settings: SettingType[] }) {
    if (response.settings.length > 0) {
      return response.settings[0];
    } else {
      return { value: "" };
    }
  }

  //check if the settings exist in the db, if they do, then update. Otherwise they need to be created
  function handleSubmit(values: FormValues) {
    if (!appId) {
      settingService.createSingle({
        number: 3,
        name: "app_id",
        appId: app.id,
        value: values.hue_app_id,
      });
    } else if (appId.id) {
      settingService.updateSingle(appId.id, { value: values.hue_app_id });
    }
    if (!clientId) {
      settingService.createSingle({
        number: 1,
        name: "app_id",
        appId: app.id,
        value: values.hue_client_id,
      });
    } else if (clientId.id) {
      settingService.updateSingle(clientId.id, { value: values.hue_client_id });
    }
    if (!clientSecret) {
      settingService.createSingle({
        number: 2,
        name: "client_secret",
        appId: app.id,
        value: values.hue_client_secret,
      });
    } else if (clientSecret.id) {
      settingService.updateSingle(clientSecret.id, {
        value: values.hue_client_secret,
      });
    }
  }
  //Get the setting object matching the 3 philips hue settings:
  //clientId(1), clientSecret(2), appId(3)
  useEffect(() => {
    settingService
      .getAll(buildParams({ number: 1, appId: app.id }))
      .then((response) => checkResponse(response))
      .then((res) => setClientId(res));
    settingService
      .getAll(buildParams({ number: 2, appId: app.id }))
      .then((response) => checkResponse(response))
      .then((res) => setClientSecret(res));
    settingService
      .getAll(buildParams({ number: 3, appId: app.id }))
      .then((response) => checkResponse(response))
      .then((res) => setAppId(res));
  }, [app.id]);

  const hueSettingsForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form layout="vertical">
          <h3>{t("translation:authentication")}</h3>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item
                name="hue_client_id"
                label={t("translation:client_id")}
              >
                <Input name="hue_client_id" className="user" size="large" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="hue_client_secret"
                label={t("translation:client_secret")}
              >
                <Input
                  name="hue_client_secret"
                  className="token"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item name="hue_app_id" label={t("translation:app_id")}>
                <Input name="hue_app_id" className="user" size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <SubmitButton type="primary" size="large" block disabled={!dirty}>
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <div className="appSettings">
      <Formik
        component={hueSettingsForm}
        initialValues={{
          hue_client_id: clientId.value,
          hue_client_secret: clientSecret.value,
          hue_app_id: appId.value,
        }}
        enableReinitialize
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values);
        }}
      />
    </div>
  );
}
