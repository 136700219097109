import { useQuery } from "@tanstack/react-query";
import { Checkbox, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import { HistoryTypes } from "/app/src/constants/types";
import { Transaction } from "/app/src/models";
import { transactionService } from "/app/src/services";

/**
 * Shows a single row of a failed transaction
 * Each row controls its own retrying state using useQuery and useEffect
 */
export default function FailedTransactionRow({
  transaction,
  selectedRowIds,
  setSelectedRowIds,
  ordersRetrying,
  setOrdersRetrying,
}: {
  transaction: Transaction;
  selectedRowIds: string[];
  setSelectedRowIds: (selectedRowIds: string[]) => void;
  ordersRetrying: string[];
  setOrdersRetrying: (ordersRetrying: string[]) => void;
}) {
  const [enabled, setEnabled] = useState<boolean>(false);

  const { data } = useQuery({
    queryKey: ["failedTransaction", transaction.id],
    queryFn: () => {
      return transactionService.getSingle(transaction.id);
    },
    initialData: { transaction },
    enabled,
    refetchInterval: 3000,
  });

  useEffect(() => {
    //if the transaction is in a retryable state, enable the query to fetch
    //every three seconds
    if ([3, 10, 11].includes(data.transaction?.exportStateType)) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [data.transaction?.exportStateType]);

  useEffect(() => {
    //if the transaction failed, remove it from the retrying list
    if (
      data.transaction?.exportStateType !== 10 &&
      ordersRetrying.includes(data.transaction.id)
    ) {
      setOrdersRetrying(
        ordersRetrying.filter((id) => id !== data.transaction.id),
      );
    }
  }, [
    data.transaction?.exportStateType,
    ordersRetrying,
    data.transaction.id,
    setOrdersRetrying,
  ]);
  return (
    <Row gutter={20} className="transaction-row">
      <Col span={6} className="name">
        {data.transaction.id}
      </Col>
      <Col span={6} className="name">
        {data.transaction.orderName}
      </Col>
      <Col span={4}>
        {
          HistoryTypes.find((type) => type.value === data.transaction.type)
            ?.name
        }
      </Col>
      <Col span={2}>
        {exportTypeToString(data.transaction?.exportStateType)}
      </Col>
      <Col span={1}>
        {[3, 10, 11].includes(data.transaction?.exportStateType) && <Spin />}
      </Col>

      <Col span={4}>
        <PowerPickTimeZoneToLocal
          date={data.transaction.creationDate}
          format={"MMMM Do YYYY, h:mm:ss.SS a"}
        />
      </Col>
      <Col span={1} className="actions">
        <div className="titleActions">
          <Checkbox
            checked={selectedRowIds.includes(transaction.id)}
            onClick={() => {
              if (selectedRowIds.includes(transaction.id)) {
                setSelectedRowIds(
                  selectedRowIds.filter(
                    (selectedRowId) => selectedRowId !== transaction.id,
                  ),
                );
              } else {
                setSelectedRowIds([...selectedRowIds, transaction.id]);
              }
            }}
            disabled={data.transaction?.exportStateType !== 5}
          />
        </div>
      </Col>
    </Row>
  );
}

/**
 * Returns a text representation of the export state type
 * @param exportStateType number to convert to string
 * @returns
 */
function exportTypeToString(exportStateType: number) {
  let color = "black";
  switch (exportStateType) {
    case 4:
      color = "green";
      return <span style={{ color }}>Success</span>;
    case 11:
    case 3:
      color = "orange";
      return <span style={{ color }}>Retrying</span>;
    case 5:
      color = "red";
      return <span style={{ color }}>Failed</span>;
    case 10:
      color = "orange";
      return <span style={{ color }}>Retrying</span>;
    default:
      color = "black";
      return <span style={{ color }}>`Unknown Type: ${exportStateType}`</span>;
  }
}
