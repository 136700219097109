import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import { Setting } from "../models";

/**
 * Get all settings
 * @param params URLSearchParams
 * @returns Promise<{ settings: Setting[] }>
 */
function getAll(
  params: URLSearchParams = null,
): Promise<{ settings: Setting[] }> {
  return genericGetAll("settings", params);
}
/**
 * Get a single setting
 * @param id number | string
 * @returns Promise<{ setting: Setting }>
 */
function getSingle(id: number | string): Promise<{ setting: Setting }> {
  return genericGetSingle("settings", id);
}

/**
 * Create a new setting
 * @param setting { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ setting: Setting }>
 */
function createSingle(setting: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ setting: Setting }> {
  return genericCreateSingle("settings", setting);
}

/**
 * Update a single setting
 * @param id number | string
 * @param setting { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ setting: Setting }>
 */
function updateSingle(
  id: number | string,
  setting: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ setting: Setting }> {
  return genericUpdateSingle("settings", id, setting);
}

/**
 * Delete a single setting
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("settings", id);
}

/**
 * Update settings
 * @param settings { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ setting: Setting }>
 */
function updateSettings(settings: {
  [key: string]: string | number | boolean | Date | undefined;
}) {
  return makeAPIRequest({ url: "/settings", body: settings, method: "put" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Get a single setting
 * @param params { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ setting: Setting }>
 */
function getSingleSetting(
  params: { [key: string]: string | number | boolean | Date | undefined } = {},
): Promise<{ setting: Setting }> {
  return makeAPIRequest({
    url: "/settings/config",
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets the count of settings
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params.append("countOnly", "true");
  return genericGetAll("settings", params);
}

export const settingService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  updateSettings,
  getSingleSetting,
  getCount,
};
