import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, Cascader, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import {
  Report as ReportType,
  ReportColumn as ReportColumnT,
  ReportColumnType,
} from "/app/src/models";
import { reportColumnService } from "/app/src/services";
import FormikDisabler from "/app/src/components/generic/components/formikDisabler";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";
import { useAccessContext } from "/app/src/contexts/hooks/useAccessContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  tableColumn: [string, number] | undefined;
  name: string | undefined;
  reportId?: number | undefined;
}

function formatForm(values: FormValues, placeholder: string): ReportColumnT {
  if (!values.name) {
    //if no name, use the placeholder value as the name
    values.name = placeholder;
  }
  const ret: ReportColumnT = {
    name: values.name,
    reportId: values.reportId,
    reportColumnTypeId: undefined,
  };
  if (values?.tableColumn) {
    ret.reportColumnTypeId = values.tableColumn[1];
  }
  return ret;
}

function cascadeReportColumnTypes(reportColumnTypes: ReportColumnType[]) {
  const selects = _.chain(reportColumnTypes)
    // Group the elements of Array based on `table` property
    .groupBy("finalTable")
    // `key` is group's name (color), `value` is the array of objects
    .map((value, key) => ({
      value: key,
      label: key,
      children: value.map((child_value, key) => ({
        value: child_value.id,
        label: child_value.name,
      })),
    }))
    .value();

  return selects;
}

function filter(inputValue: string, path: any[]) {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
  );
}

export default function NewReportColumn({
  report,
  reportColumnTypes,
}: {
  report: ReportType;
  reportColumnTypes: ReportColumnType[];
}) {
  const { t } = useTranslation();

  const [namePlaceholder, setNamePlaceholder] = useState("");
  const { canEdit } = useAccessContext();
  const queryClient = useQueryClient();
  const { mutateAsync: addColumn } = useMutation({
    mutationFn: (values: ReportColumnT) => {
      return reportColumnService.createSingle(values).then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["reportColumns", report.id],
        (oldData: { report_columns: ReportColumnT[] }) => {
          return {
            report_columns: [...oldData.report_columns, data.report_column],
          };
        },
      );
    },
  });

  const addColumnHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      values.reportId = report.id;
      await addColumn(formatForm(values, namePlaceholder)).then(() => {
        actions.resetForm();
        //reset the placeholder name to default value
        setNamePlaceholder(t("translation:enter_name"));
      });
    },
    [addColumn, report.id, namePlaceholder, t],
  );

  useEffect(() => {
    setNamePlaceholder(t("translation:enter_name"));
  }, [t]);

  const adjustPlaceholder = useMemo(
    () => (e: [string, number]) => {
      //set the report column's placeholder name
      if (e[1] === 0) {
        setNamePlaceholder(t("translation:select_column"));
      }
      const col = reportColumnTypes.filter((c) => c.id === e[1])[0];
      //include the table name if the baseTable doesn't match table
      if (col.baseTable !== col.finalTable) {
        setNamePlaceholder(`${col.finalTable} ${col.name}`);
      } else {
        if (col.name) {
          setNamePlaceholder(col.name);
        }
      }
    },
    [reportColumnTypes, t],
  );

  const newColumnForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, isSubmitting }) => (
        <Form>
          <FormikDisabler disabled={!canEdit} />
          <Row justify="start" gutter={16}>
            <Col span={10}>
              <Form.Item name="tableColumn">
                <Cascader
                  name="tableColumn"
                  size="large"
                  className="larger-cascade"
                  options={cascadeReportColumnTypes(reportColumnTypes)}
                  placeholder={t("translation:select_column")}
                  showSearch={{ filter }}
                  onChange={adjustPlaceholder}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name="name" hasFeedback={false}>
                <Input name="name" placeholder={namePlaceholder} size="large" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <DisabledSubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
              >
                {t("translation:add_column")}
              </DisabledSubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [canEdit, reportColumnTypes, t, adjustPlaceholder, namePlaceholder],
    );
  return (
    <Formik
      component={newColumnForm}
      initialValues={{ tableColumn: undefined, name: "" }}
      validationSchema={simpleSchemaBuilder([
        { name: "tableColumn", type: "array", required: true },
      ])}
      onSubmit={addColumnHandler}
    />
  );
}
