import React, { useCallback, useMemo } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { HistoryTypes, MotiveTypes } from "/app/src/constants/types";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

const { Option } = Select;

interface FormValues {
  value: number[] | undefined;
}

function setInitialValues(value: string | undefined) {
  if (value) {
    //split the comma-separated string to array of numbers
    return value.split(",").map(Number);
  }
  return undefined;
}

export default function Filters({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const historyTypeOptions: JSX.Element[] = useMemo(() => {
    const options = [];
    HistoryTypes.forEach((hType) => {
      options.push(
        <Option key={hType.value} value={hType.value}>
          {hType.name}
        </Option>,
      );
    });
    return options;
  }, []);

  const motiveTypeOptions: JSX.Element[] = useMemo(() => {
    const options = [];
    MotiveTypes.forEach((mType) => {
      options.push(
        <Option key={mType.value} value={mType.value}>
          {mType.name}
        </Option>,
      );
    });
    return options;
  }, []);

  const exportStateFilterQuery = useQuery({
    queryKey: ["exportStateFilter", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({
          integrationId: integration.id,
          type: "filter",
          number: 1,
        }),
      ),
  });

  const { mutateAsync: exportState } = useMutation({
    mutationFn: (values: FormValues) => {
      if (exportStateFilterQuery.data?.settings.length < 1) {
        return settingService
          .createSingle({
            name: "filter",
            number: 1,
            value: values.value.toString(),
            type: "filter",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      } else {
        return settingService
          .updateSingle(exportStateFilterQuery.data?.settings[0].id, {
            name: "filter",
            number: 1,
            value: values.value.toString(),
            type: "filter",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["exportStateFilter", integration.id], {
        settings: [data?.setting],
      });
    },
  });

  const exportStateHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await exportState(values);
      actions.resetForm();
    },
    [exportState],
  );

  const motiveTypeFilterQuery = useQuery({
    queryKey: ["motiveTypeFilter", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({
          integrationId: integration.id,
          type: "filter",
          number: 2,
        }),
      ),
  });

  const { mutateAsync: motiveType } = useMutation({
    mutationFn: (values: FormValues) => {
      if (motiveTypeFilterQuery.data?.settings.length < 1) {
        return settingService
          .createSingle({
            name: "filter",
            number: 2,
            value: values.value.toString(),
            type: "filter",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      } else {
        return settingService
          .updateSingle(motiveTypeFilterQuery.data?.settings[0].id, {
            name: "motiveType",
            number: 2,
            value: values.value.toString(),
            type: "filter",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      }
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["motiveTypeFilter", integration.id], {
        settings: [data?.setting],
      });
    },
  });

  const motiveTypeHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await motiveType(values);
      actions.resetForm();
    },
    [motiveType],
  );
  /**
   * Form component for creating or updating the history type filter
   */
  const exportStateTypeFilterForm: (
    props: FormikProps<FormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={20}>
            <Form.Item name="value" label={t("translation:history_type")}>
              <Select
                size="large"
                name="value"
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder={t("translation:please_select")}
              >
                {historyTypeOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t, historyTypeOptions],
  );
  /**
   * Form component for creating or updating the motive type filter
   */
  const motiveTypeFilterForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={20}>
              <Form.Item name="value" label={t("translation:motive_type")}>
                <Select
                  size="large"
                  name="value"
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={t("translation:please_select")}
                >
                  {motiveTypeOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, motiveTypeOptions],
    );
  return (
    <div className="box">
      <h1>{"Filters"}</h1>
      <Formik
        component={exportStateTypeFilterForm}
        initialValues={{
          value: setInitialValues(
            exportStateFilterQuery.data?.settings[0]?.value,
          ),
        }}
        enableReinitialize
        onSubmit={exportStateHandler}
      />
      <Formik
        component={motiveTypeFilterForm}
        initialValues={{
          value: setInitialValues(
            motiveTypeFilterQuery.data?.settings[0]?.value,
          ),
        }}
        enableReinitialize
        onSubmit={motiveTypeHandler}
      />
    </div>
  );
}
