import React, { useState, useEffect, useContext } from "react";
import { ResponsiveLineCanvas, Serie } from "@nivo/line";
import { getDateFormat } from "/app/src/helpers/time";
import TimeZoneContext from "/app/src/contexts/TimeZoneContext";
import { snapshotDataService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";

export default function LineChart({ snapshotId }: { snapshotId: number }) {
  const [data, setData] = useState<Serie[]>([]);
  const { timeZone } = useContext(TimeZoneContext);
  const [frequency, setFrequency] = useState<{
    name:
      | "millisecond"
      | "second"
      | "minute"
      | "hour"
      | "day"
      | "month"
      | "year"
      | undefined;
    xFormat: string;
    axisFormat: string;
    interval: Number;
    ticks?: string;
  }>({
    name: "day",
    xFormat: "%Y-%m-%d",
    axisFormat: "%b %d",
    interval: 10,
  });

  useEffect(() => {
    function updateFrequency(value: string) {
      switch (value) {
        case "minute":
          setFrequency({
            name: "minute",
            xFormat: " %b %d, %I:%M %p",
            axisFormat: "%I:%M %p",
            interval: 60,
          });
          break;
        case "hour":
          setFrequency({
            name: "hour",
            xFormat: " %b %d, %I:%M %p",
            axisFormat: "%I:%M %p",
            interval: 24,
          });
          break;
        case "day":
          setFrequency({
            name: "day",
            xFormat: "%Y-%m-%d",
            axisFormat: "%b %d",
            interval: 10,
          });
          break;
        case "week":
          setFrequency({
            name: "day",
            xFormat: "%Y-%m-%d",
            axisFormat: "%b %d",
            interval: 10,
          });
          break;
        case "month":
          setFrequency({
            name: "month",
            xFormat: "%Y-%m-%d",
            axisFormat: "%b %d",
            interval: 10,
          });
          break;
        case "year":
          setFrequency({
            name: "year",
            xFormat: "%Y-%m",
            axisFormat: "%b %d",
            interval: 10,
          });
          break;
        default:
          console.log("frequency value did not match any case");
      }
    }

    snapshotDataService
      .getAll(
        snapshotId,
        buildParams({ isHistorical: "true", refresh: "true" }),
      )
      .then((response) => {
        // Format
        setData(addData(data, response.snapshot.data, timeZone));

        if (response?.snapshot?.trigger?.triggerFrequency) {
          updateFrequency(response.snapshot.trigger.triggerFrequency);
        }
      });
  }, [snapshotId, data, timeZone]);

  return (
    <div className="chart time">
      {data && (
        <ResponsiveLineCanvas
          data={data}
          enableGridX={false}
          curve="catmullRom"
          margin={{ top: 25, right: 0, bottom: 20, left: 50 }}
          xScale={{
            type: "time",
            format: "%s",
            useUTC: false,
            precision: frequency.name,
          }}
          lineWidth={4}
          pointSize={10}
          pointBorderWidth={2}
          pointColor={"#FFFFFF"}
          pointBorderColor={{ from: "serieColor" }}
          xFormat={`time:${frequency.xFormat}`}
          axisBottom={{
            format: frequency.axisFormat,
            tickValues: `every ${frequency.interval} ${frequency.name}s`,
          }}
        />
      )}
    </div>
  );
}

function addData(data: Serie[], newData: Serie, timeZone: string) {
  // Loop through grouped sets

  Object.keys(newData).forEach((key) => {
    const group: { id: string; data: { x: string; y: number }[] } = {
      id: key,
      data: [],
    };
    // Loop through records for each item

    newData[key].forEach((item: { name: string; value: number }) => {
      const record = {
        x: getDateFormat(item["name"], "X", timeZone),
        y: item["value"],
      };

      group["data"].push(record);
    });
    data.push(group);
  });
  return data;
}
