import { Divider } from "antd";
import PowerPickChart from "./powerPickChart";
import { useTranslation } from "react-i18next";
import { useConnectionStatusContext } from "/app/src/contexts/hooks/useConnectionStatusContext";
import StatusHook from "/app/src/components/status/statusHook";

/**
 * Component for displaying connection status
 * @returns JSX.Element
 *
 */
export default function ConnectionStatus() {
  const { t } = useTranslation();
  const { webServices } = useConnectionStatusContext();
  const { powerPickMSSQL, powerPickWebServices } = StatusHook();

  return (
    <div>
      <div className="box">
        <div className="box-header">
          <h3 className="chart-title">{t("translation:powerpick_mssql")}</h3>
          <Divider />
          <PowerPickChart data={powerPickMSSQL} />
        </div>
        <div>&nbsp;</div>
        <div className="box-header">
          <h3 className="chart-title" style={{ paddingTop: 20 }}>
            {t("translation:powerpick_web_services")}
          </h3>
          <Divider />
          <PowerPickChart disabled={webServices} data={powerPickWebServices} />
        </div>
      </div>
    </div>
  );
}
