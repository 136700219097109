import { useQuery } from "@tanstack/react-query";
import { buildParams } from "/app/src/helpers";
import { logService } from "/app/src/services";
import { Log } from "/app/src/models";

/**
 * Hook to get logs, logsCount, and isLoading for a given set of query params
 */
export function useLogs(
  queryParams: { [key: string]: string | number },
  queryCountParams: { [key: string]: string | number },
) {
  const { data: logs, isLoading } = useQuery({
    queryKey: ["logs", queryParams],
    queryFn: () => {
      //   const creationDateQuery = buildCreationDateQuery(beforeTime, afterTime);
      const params = buildParams(queryParams);
      return logService.getAll(params);
    },
    initialData: { logs: [] },
    select: (data: { logs: Log[] }) => data.logs,
  });

  const { data: logsCount } = useQuery({
    queryKey: ["logsCount", queryCountParams],
    queryFn: () => {
      return logService.getCount(buildParams({ ...queryCountParams }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => data.count,
  });

  return { logs, isLoading, logsCount };
}
