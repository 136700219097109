export const themes = [
  { name: "API Call", value: "APICall" },
  { name: "Batch", value: "Batch" },
  { name: "Bin", value: "Bin" },
  { name: "Carrier", value: "Carrier" },
  { name: "Cost Center", value: "CostCenter" },
  { name: "Client", value: "Client" },
  { name: "History", value: "History" },
  { name: "Job", value: "Job" },
  { name: "Job Line", value: "JobLine" },
  { name: "Kit", value: "Kit" },
  { name: "Kit Line", value: "KitLine" },
  { name: "Location", value: "Location" },
  { name: "Location Content", value: "LocationContent" },
  { name: "Location Content Breakdown", value: "LocationContentBreakdown" },
  { name: "Log", value: "Log" },
  { name: "Material", value: "Material" },
  { name: "Material Family", value: "MaterialFamily" },
  { name: "Order", value: "MasterOrder" },
  { name: "Order Line", value: "MasterOrderLine" },
  { name: "Shelf", value: "Shelf" },
  { name: "Storage Rule", value: "StorageRule" },
  { name: "Storage Unit", value: "StorageUnit" },
  { name: "Task", value: "Task" },
  { name: "User", value: "PowerPickUser" },
  { name: "Warehouse", value: "Warehouse" },
  { name: "Zone", value: "Zone" },
];

export const tables = [
  { name: "Access Point", value: "AccessPoint" },
  { name: "API Call", value: "APICall" },
  { name: "App", value: "App" },
  { name: "Batch", value: "Batch" },
  { name: "Bin", value: "Bin" },
  { name: "Carrier", value: "Carrier" },
  { name: "Cost Center", value: "CostCenter" },
  { name: "Client", value: "Client" },
  { name: "History Master Order", value: "HistoryMasterOrder" },
  { name: "History Master Order Line", value: "HistoryMasterOrderLine" },
  { name: "History", value: "History" },
  { name: "Integration", value: "Integration" },
  { name: "Job", value: "Job" },
  { name: "Job Line", value: "JobLine" },
  { name: "Kit", value: "Kit" },
  { name: "Kit Line", value: "KitLine" },
  { name: "Location", value: "Location" },
  { name: "Location Content", value: "LocationContent" },
  { name: "Location Content Breakdown", value: "LocationContentBreakdown" },
  { name: "Log", value: "Log" },
  { name: "Material", value: "Material" },
  { name: "Material Family", value: "MaterialFamily" },
  { name: "Material Property", value: "MaterialProperty" },
  { name: "Order", value: "MasterOrder" },
  { name: "Order Line", value: "MasterOrderLine" },
  { name: "Power Pick User Group", value: "PowerPickUserGroup" },
  { name: "Shelf", value: "Shelf" },
  { name: "Storage Rule", value: "StorageRule" },
  { name: "Storage Unit", value: "StorageUnit" },
  { name: "Supplement", value: "Supplement" },
  { name: "Task", value: "Task" },
  { name: "User", value: "PowerPickUser" },
  { name: "Variance", value: "Variance" },
  { name: "Warehouse", value: "Warehouse" },
  { name: "Workflow", value: "Workflow" },
  { name: "Zone", value: "Zone" },
];

export const epicorThemes = [
  { name: "History", value: "History" },
  { name: "Log", value: "Log" },
  { name: "Kit", value: "Kit" },
  { name: "Kit Line", value: "KitLine" },
  { name: "Material", value: "Material" },
  { name: "Material Family", value: "MaterialFamily" },
  { name: "Material Property", value: "MaterialProperty" },
  { name: "Master Order", value: "MasterOrder" },
  { name: "Master Order Line", value: "MasterOrderLine" },
  { name: "Storage Rule", value: "StorageRule" },
  { name: "Storage Unit", value: "StorageUnit" },
  { name: "Task", value: "Task" },
];
