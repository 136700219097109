import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { materialService, dynamicFieldService } from "/app/src/services";
import Search from "../search";
import Table from "/app/src/components/generic/tables/table";
import { useSort } from "/app/src/hooks";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { DynamicField, Material as MaterialType } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { useQuery } from "@tanstack/react-query";

type Header = {
  Header: string | undefined;
  accessor: string | undefined;
};

export default function Materials() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columns, setColumns] = useState<Header[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const [searchString, setSearchString] = useState("");

  const goToMaterial = (materialId: number) => {
    navigate(`/explorer/materials/${materialId}`);
  };

  const { data: materials, isFetching } = useQuery({
    queryKey: ["materials", page, pageSize, searchString, sort],
    queryFn: () => {
      return materialService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { materials: [] },
    select: (data: { materials: MaterialType[] }) => {
      return data.materials;
    },
  });

  const { data: materialsCount } = useQuery({
    queryKey: ["materialsCount", searchString],
    queryFn: () => {
      return materialService.getCount(
        buildParams({
          search: searchString,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: dynamicFields } = useQuery({
    queryKey: ["dynamicFields", "Materialbase"],
    queryFn: () => {
      return dynamicFieldService.getAll(
        buildParams({
          baseTable: "Materialbase",
        }),
      );
    },
    initialData: { dynamic_fields: [] },
    select: (data: { dynamic_fields: DynamicField[] }) => {
      return data.dynamic_fields;
    },
  });

  //Modify the columns
  useEffect(() => {
    const Columns: Header[] = [
      {
        Header: t("translation:name"),
        accessor: "name",
      },
    ];

    dynamicFields.map((dynamicField) =>
      Columns.push({
        Header: dynamicField.name,
        accessor: dynamicField.field,
      }),
    );
    setColumns(Columns);
  }, [dynamicFields, t]);

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:materials")} - ItemPath</title>
      </Helmet>
      <div className="search box">
        <Search setSearchString={setSearchString} />
      </div>
      <Table
        loading={isFetching}
        rows={materials}
        tableColumns={columns}
        length={materialsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        rowClicked={goToMaterial}
        emptyText={t("translation:no_materials")}
      />
    </div>
  );
}
