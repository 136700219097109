import React, { useState } from "react";

import Table from "/app/src/components/generic/tables/table";
import { Sort } from "/app/src/types";
import { Column } from "react-table";
import { useLogs } from "./data/useLogs";
import getOrderByQuery from "/app/src/helpers/table";
import { DefaultColumns } from "./data/defaultColumns";
import { Log } from "/app/src/models";
/**
 * Shows logs for a task
 * @param param0 object containing taskId, mappings, notFoundText and notSelectedText
 */
export default function LogsTable({
  notFoundText,
  rowClicked,
  params,
  columns = [],
}: {
  notFoundText: string;
  rowClicked?: (row: number) => void;
  params: { [key: string]: string | number };
  columns?: Column<Log>[];
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useState<Sort>([]);
  const { logsCount, logs, isLoading } = useLogs(
    { page, pageSize, orderby: getOrderByQuery(sort), ...params },
    params,
  );

  return (
    <div className="workflow">
      <Table
        loading={isLoading}
        rows={logs}
        tableColumns={columns ? columns : DefaultColumns()}
        length={logsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        emptyText={notFoundText}
        rowClicked={rowClicked}
      />
    </div>
  );
}
