import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";
import { Log } from "/app/src/models";

/**
 * Map the status of a log to a string. Defaults to empty string
 */
function status(value: number, mappings: { 1?: string; 2?: string } = {}) {
  if (value in mappings) {
    return mappings[value];
  }
  return "";
}

/**
 * Default columns for the logs table
 */
export function DefaultColumns(): Column<Log>[] {
  const { t } = useTranslation();

  const columns: Column<Log>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:type"),
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <p>
            {status(value, {
              1: t("translation:success_created"),
              2: t("translation:failed_created"),
            })}
          </p>
        ),
      },
      {
        Header: t("translation:description"),
        accessor: "description",
      },
      {
        Header: t("translation:errors"),
        accessor: "error",
      },
    ];
  }, [t]);
  return columns;
}
