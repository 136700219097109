import Number from "./number";
import { Row } from "antd";
import { NumberChartData } from "./types";

export default function NumberChart({ data }: { data: NumberChartData }) {
  return (
    <div className="numberChart">
      <Row>
        {data.data.map((dataset) => (
          <Number
            dataset={dataset}
            snapshotsAmount={data.data.length}
            key={dataset.label}
          />
        ))}
      </Row>
    </div>
  );
}
