import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { App, Integration } from "/app/src/models";

/**
 * Component for Data Push integration menu. Shows settings, mappings, logs
 * manually send, and failed attempts.
 * @param app Data Push app
 * @param integration Data Push integration
 */
export default function IntegrationMenu({
  app,
  integration,
}: {
  app: App;
  integration: Integration;
}) {
  const { t } = useTranslation();
  return (
    <div className="integrationMenu sideMenu">
      <div className="box">
        <ul>
          <li>
            <NavLink end to={`/apps/${app.id}/integrations/${integration.id}`}>
              {t("translation:settings")}
            </NavLink>
          </li>
          <li>
            <NavLink to={`/apps/${app.id}/integrations/${integration.id}/logs`}>
              {t("translation:logs")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/apps/${app.id}/integrations/${integration.id}/mappings`}
            >
              {t("translation:mappings")}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
