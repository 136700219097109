import React from "react";
import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";
import { Row, Col } from "antd";
import Menu from "./_menu";
import Controls from "./_controls";

import General from "./general";
import Kardex from "./kardex";
import App from "./app";
import Apps from "./apps";
import Connections from "./connections";
import CustomColumnTypes from "./customColumnTypes";

export default function Settings() {
  return (
    <div className="settings">
      <Helmet>
        <title>Settings - ItemPath</title>
      </Helmet>
      <Controls title="Settings" />
      <Row gutter={20}>
        <Col span={4}>
          <Menu />
        </Col>
        <Col span={20}>
          <Routes>
            <Route element={<General />} path="/" />
            <Route element={<Kardex />} path="kardex" />
            <Route element={<Connections />} path="connections" />
            <Route element={<CustomColumnTypes />} path="report_column_types" />
            <Route element={<Apps />} path="apps" />
            <Route element={<App />} path="apps/:appId/*" />
          </Routes>
        </Col>
      </Row>
    </div>
  );
}
