import React, { useState, useCallback } from "react";
import { Form, SubmitButton, Input } from "formik-antd";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col, Button, message } from "antd";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { webServicesSchema, webServicesSchemaNoPwd } from "/app/src/schemas";
import { connectionService } from "/app/src/services";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { testConnection } from "/app/src/helpers/connectionsStatus";
import Setting from "/app/src/components/settings/setting";
import { Connection as ConnectionType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import ToolTip from "/app/src/components/generic/components/toolTip";
import { handlePromiseError } from "/app/src/helpers/api";
import { useConnectionStatusContext } from "/app/src/contexts/hooks/useConnectionStatusContext";
import { ConnectionState } from "/app/src/contexts/types";
import { handleSubmissionErrors } from "/app/src/helpers";

/**
 * Formats the form values to be sent to the API
 * @param values Form values
 */
function formatForm(values: FormValues) {
  const formatted: {
    username: string | undefined;
    url: string | undefined;
    password?: string;
  } = {
    username: values.username,
    url: values.url,
  };
  if (values.password) {
    formatted.password = values.password;
  }
  return formatted;
}

interface FormValues {
  url: string | undefined;
  username: string | undefined;
  password?: string;
}

/**
 * Component to display the PowerPick Web Services connection settings
 */
export default function WebServices() {
  const { t } = useTranslation();
  const { powerPick } = useConnectionStatusContext();
  const [isTesting, setIsTesting] = useState(false);

  const queryClient = useQueryClient();

  const { data: connection } = useQuery({
    queryKey: ["connections1", { type: "PowerPick Web Services" }],
    queryFn: () => {
      return connectionService
        .getAll(buildParams({ type: "PowerPick Web Services" }))
        .then((response) => {
          return { connection: response.connections[0] };
        });
    },
    initialData: { connection: {} as ConnectionType },
    select: (data) => data.connection,
  });

  const { mutateAsync: updateConnection } = useMutation({
    mutationFn: (connection: ConnectionType) => {
      return connectionService
        .updateSingle(connection.id, connection)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["connections1", { type: "PowerPick Web Services" }],
        response,
      );
      queryClient.setQueryData(
        ["powerpickConnection"],
        (old: ConnectionState) => {
          return {
            ...old,
            webServices: response.status ? 1 : 0,
          };
        },
      );
    },
  });

  //use callback for test handler
  const testHandler = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      if (!powerPick) {
        message.error(t("translation:powerpick_connection_required"));
        return;
      }
      testConnection(setIsTesting, connection, updateConnection);
    },
    [powerPick, connection, updateConnection, t],
  );

  const onFocusHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.placeholder = "";
    },
    [],
  );

  const onBlurHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (connection.passwordExists) {
        e.target.placeholder = "***********";
      }
    },
    [connection.passwordExists],
  );

  /**
   * Component to display the PowerPick Web Services connection settings
   * @param param FormikProps<FormValues>
   * @returns JSX.Element
   */
  const connectionForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16} style={{ paddingBottom: "10px" }}>
            <Col span={20}>
              <h2>{t("translation:web_services_connection")}</h2>
            </Col>
            <Col
              span={1}
              style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {connection.status === 0 ? (
                <WarningTwoTone
                  twoToneColor="red"
                  style={{ fontSize: "32px" }}
                />
              ) : (
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ fontSize: "32px" }}
                />
              )}
            </Col>
            <Col span={3}>
              <div style={{ float: "right" }}>
                <Button disabled={isTesting} onClick={testHandler}>
                  {isTesting
                    ? t("translation:testing___")
                    : t("translation:test_") + t("translation:connection")}
                </Button>
              </div>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <h3>{t("translation:web_services_url")}</h3>
              <div>
                <span>{t("translation:web_services_url_description")}</span>
                <ToolTip tip={t("translation:web_services_url_tooltip")} />
              </div>
            </Col>
            <Col span={14} offset={2}>
              <Form.Item name="url" className="no-label">
                <Input suffix name="url" className="token" size="large" />
              </Form.Item>
              <Setting
                settingNumber={201}
                settingType="checkbox"
                defaultValue="1"
                appId={null}
              />
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <h3>{t("translation:power_pick_user_password")}</h3>
              <p>{t("translation:ppg_user_access_rights")}</p>
            </Col>
            <Col span={6} offset={2}>
              <Form.Item name="username" className="no-label">
                <Input
                  suffix
                  placeholder={t("translation:power_pick_user")}
                  name="username"
                  className="user"
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="password" className="no-label">
                <Input.Password
                  className="password"
                  onFocus={onFocusHandler}
                  onBlur={onBlurHandler}
                  placeholder={
                    connection.passwordExists
                      ? "***********"
                      : t("translation:power_pick_password")
                  }
                  name="password"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={4} offset={20}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, connection, isTesting, testHandler, onFocusHandler, onBlurHandler],
    );

  /**
   * Submits the form to update the connection
   * @param values { username: string | undefined; url: string | undefined; password?: string;}
   * @param actions FormikHelpers<FormValues>
   */
  const handleSubmit = useCallback(
    async (
      values: {
        username: string | undefined;
        url: string | undefined;
        password?: string;
      },
      actions: FormikHelpers<FormValues>,
    ) => {
      return await updateConnection({
        id: connection.id,
        ...formatForm(values),
      }).catch((errors) => {
        handleSubmissionErrors(errors, actions.setFieldError);
      });
    },
    [connection, updateConnection],
  );

  return (
    <div className="box">
      <Formik
        component={connectionForm}
        validationSchema={
          connection.passwordExists ? webServicesSchemaNoPwd : webServicesSchema
        }
        initialValues={{
          url: connection.url,
          username: connection.username,
          password: "",
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      />
    </div>
  );
}
