import { useCallback } from "react";
import { BarDatum, ComputedDatum, ResponsiveBar } from "@nivo/bar";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

/**
 * Displays the PowerPickSQL and PowerPick Web services bar chart
 * @param data, disabled
 * @returns ResponsiveBar
 */
export default function PowerPickChart({
  data,
  disabled,
}: {
  data: BarDatum[];
  disabled?: number;
}) {
  const { t } = useTranslation();
  /**
   * getColor - gets the colour of the passed in bar
   * @param bar the bar to get the colour for
   * @returns colour of the bar
   */
  const getColor = useCallback((bar: ComputedDatum<BarDatum>) => {
    const colorKey = "statusColor";
    return bar.data[colorKey] as string;
  }, []);

  if (disabled === 0) {
    return (
      <div style={{ textAlign: "center", paddingTop: 30 }}>
        <CloseCircleTwoTone style={{ fontSize: 50 }} twoToneColor="#d0021b" />
        <h3 style={{ paddingTop: 20 }}>
          {t("translation:no_powerpick_web_services")}
        </h3>
      </div>
    );
  } else {
    return (
      <div className="status-chart">
        <ResponsiveBar
          data={data}
          keys={["status"]}
          colors={getColor}
          groupMode="grouped"
          indexBy="creationDate"
          enableGridY
          gridYValues={[0]}
          padding={0.3}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisBottom={{
            tickSize: 10,
            tickPadding: 10,
            tickRotation: -35,
          }}
          maxValue={5}
          enableLabel={false}
          isInteractive={false}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: "10px",
                },
              },
            },
          }}
        />
      </div>
    );
  }
}
