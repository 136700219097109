import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Integration, Log } from "/app/src/models";
import { buildCreationDateQuery, getJSON } from "/app/src/helpers";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import Controls from "./_controls";
import ToolTip from "/app/src/components/generic/components/toolTip";
import LogsTable from "/app/src/components/generic/components/tasks/logsTable";
import { Column } from "react-table";

/**
 * Component for displaying the logs of a data push integration
 * @param integration the integration to display the logs for
 */
export default function DataPushLogs({
  integration,
}: {
  integration: Integration;
}) {
  const [searchString, setSearchString] = useState("");
  const [beforeTime, setBeforeTime] = useState("");
  const [afterTime, setAfterTime] = useState("");
  const [status, setStatus] = useState<string>("");
  const { t } = useTranslation();
  const [params, setParams] = useState(undefined);
  useEffect(() => {
    setParams({
      integrationId: integration.id,
      search: searchString,
      creationDate: buildCreationDateQuery(beforeTime, afterTime),
      status,
    });
  }, [searchString, beforeTime, afterTime, status, integration.id]);

  /**
   * Converts the data to json and then returns the value
   * of the passed in key
   * @param data json string
   * @param key key to return the value of
   */
  function parseOutData(data: string, key: string) {
    try {
      const parsedData = JSON.parse(data);
      //convert to string to avoid react error
      return JSON.stringify(parsedData[key]);
    } catch (e) {
      return data;
    }
  }

  const columns: Column<Log>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:creationDate"),
        accessor: "creationDate",
        Cell: ({ cell: { value } }) => {
          return <DateTime date={value} />;
        },
      },
      {
        Header: () => (
          <>
            {t("translation:time_ms")}
            <ToolTip tip={t("translation:time_description")} />
          </>
        ),
        accessor: "time",
      },
      {
        Header: t("translation:transaction_id"),
        accessor: "taskName",
      },
      {
        Header: t("translation:sent_data"),
        accessor: "description",
        Cell: ({ cell: { value } }) => <p>{parseOutData(value, "Sent")}</p>,
      },
      {
        Header: t("translation:response"),
        accessor: "description",
        id: "response",
        Cell: ({ cell: { value } }) => (
          <p>{getJSON(parseOutData(value, "Received"))}</p>
        ),
      },
      {
        Header: t("translation:errors"),
        accessor: "error",
        id: "error",
      },
      {
        Header: (
          <>
            {t("translation:status")}
            <ToolTip tip={t("translation:status_description")} />
          </>
        ),
        accessor: "status",
      },
    ];
  }, [t]);

  return (
    <div>
      <Controls
        setSearchString={setSearchString}
        setBeforeTime={setBeforeTime}
        setAfterTime={setAfterTime}
        setStatus={setStatus}
      />
      {params && (
        <LogsTable
          notFoundText={t("translation:no_logs_found")}
          columns={columns}
          params={params}
        />
      )}
    </div>
  );
}
