import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Connection } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { logService } from "/app/src/services";
import { buildCreationDateQuery, buildParams } from "/app/src/helpers";
import { Sort } from "/app/src/types";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import Table from "/app/src/components/generic/tables/table";
import Controls from "./_controls";
import getOrderByQuery from "/app/src/helpers/table";

/**
 * Component for displaying the logs of a connection
 * @param connection the connection to display the logs for
 */
export default function Logs({ connection }: { connection: Connection }) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sort, setSort] = useState<Sort>([]);
  const [searchString, setSearchString] = useState("");
  const [beforeTime, setBeforeTime] = useState("");
  const [afterTime, setAfterTime] = useState("");
  const { t } = useTranslation();

  const { data, isFetching } = useQuery({
    queryKey: [
      "logsByConnection",
      connection.id,
      page,
      pageSize,
      searchString,
      beforeTime,
      afterTime,
      sort,
    ],
    queryFn: () => {
      const creationDateQuery = buildCreationDateQuery(beforeTime, afterTime);
      const params = buildParams({
        connectionId: connection.id,
        page,
        limit: pageSize,
        search: searchString,
        creationDate: creationDateQuery,
        orderby: getOrderByQuery(sort),
      });
      return logService.getAll(params);
    },
    initialData: { logs: [] },
  });

  const { data: logLength } = useQuery({
    queryKey: [
      "logsCountByConnection",
      connection.id,
      beforeTime,
      afterTime,
      searchString,
    ],
    queryFn: () => {
      const creationDateQuery = buildCreationDateQuery(beforeTime, afterTime);
      const params = buildParams({
        connectionId: connection.id,
        search: searchString,
        creationDate: creationDateQuery,
      });
      return logService.getCount(params);
    },
    initialData: { count: 0 },
  });

  /**
   * Converts the data to json and then returns the value
   * of the passed in key
   * @param data json string
   * @param key key to return the value of
   */
  function parseOutData(data: string, key: string) {
    try {
      const parsedData = JSON.parse(data);
      //convert to string to avoid react error
      return JSON.stringify(parsedData[key]);
    } catch (e) {
      return data;
    }
  }

  const columns = React.useMemo(() => {
    return [
      {
        Header: t("translation:creationDate"),
        accessor: "creationDate",
        Cell: ({ cell: { value } }) => {
          return <DateTime date={value} />;
        },
      },
      {
        Header: t("translation:sent_data_info"),
        accessor: "description",
        Cell: ({ cell: { value } }) => <p>{parseOutData(value, "Sent")}</p>,
      },
      {
        Header: t("translation:response_info"),
        accessor: "description",
        id: "response",
        Cell: ({ cell: { value } }) => <p>{parseOutData(value, "Received")}</p>,
      },
    ];
  }, [t]);

  return (
    <div>
      <Controls
        setSearchString={setSearchString}
        setBeforeTime={setBeforeTime}
        setAfterTime={setAfterTime}
      />
      <Table
        rows={data.logs}
        tableColumns={columns}
        length={logLength.count}
        sort={sort}
        loading={isFetching}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize,
          setPage,
          setPageSize,
        }}
        emptyText={t("translation:no_logs_found")}
        classStyle="dataTable ghost"
      />
    </div>
  );
}
