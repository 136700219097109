import { Spin, Col } from "antd";
import { NumberDatum } from "./types";

export default function Number({
  dataset,
  snapshotsAmount,
  isLoading,
}: {
  dataset?: NumberDatum;
  snapshotsAmount?: number;
  isLoading?: boolean;
}) {
  if (isLoading) {
    return <Spin />;
  } else {
    return (
      <Col span={snapshotsAmount > 2 ? 12 : 24}>
        <div
          className={
            snapshotsAmount === 1
              ? "single number"
              : snapshotsAmount === 2
              ? "double number"
              : "quad number"
          }
        >
          <h1 style={{ color: dataset.color }}>{dataset.value}</h1>
          <span>{dataset.label}</span>
        </div>
      </Col>
    );
  }
}
