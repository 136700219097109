import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton } from "formik-antd";
import { useTranslation } from "react-i18next";

import { settingService } from "/app/src/services";
import { getTimeZones } from "/app/src/helpers/time";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { Setting as SettingType } from "/app/src/models";
import { mapTimeZones } from "/app/src/components/generic/formatting/timeZone";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

/**
 * Component to display the form for updating the powerpick timezone
 */
export default function PowerPickTimeZone() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  //useQuery for getting the powerpick timezone
  const { data: powerpickTimeZone } = useQuery({
    queryKey: ["powerpickTimeZone"],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 107 }))
        .then((response) => {
          return response.settings[0];
        });
    },
    initialData: { value: "" },
  });
  //useMutation for updating the powerpick timezone
  const { mutateAsync: updatePowerpickTimezone } = useMutation({
    mutationFn: (values: { value: string }) => {
      return settingService
        .updateSingle(powerpickTimeZone.id, { value: values.value })
        .then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["powerpickTimeZone"], data.setting);
    },
  });

  //onSubmit handler for updating the time zone
  const onSubmitTimezone = useCallback(
    async (
      { value: timeZone }: { value: string },
      {
        setFieldError,
      }: { setFieldError: (field: string, value: string) => void },
    ): Promise<void> => {
      await updatePowerpickTimezone({ value: timeZone }).catch((error) => {
        return setFieldError("value", error.errors[0]);
      });
    },
    [updatePowerpickTimezone],
  );
  /**
   * Update time zone form
   */
  const updateTimeZoneForm: (props: FormikProps<SettingType>) => JSX.Element =
    useCallback(
      ({ dirty }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <h3>{t("translation:powerpick_time_zone")}</h3>
              <p>{t("translation:powerpick_time_zone_description")}</p>
            </Col>
            <Col span={13}>
              <Form.Item name="value" label={t("translation:Time_Zone")}>
                <SearchFilter
                  list={getTimeZones()}
                  name="value"
                  placeholder={t("translation:select_time_zone")}
                  sort
                  mapOptionsFn={mapTimeZones}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <SubmitButton
                className="no-label" // skipcq: JS-0394
                type="primary"
                size="large"
                block
                disabled={!dirty}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <Formik
      component={updateTimeZoneForm}
      initialValues={{
        value: powerpickTimeZone.value,
      }}
      enableReinitialize
      onSubmit={onSubmitTimezone}
    />
  );
}
