import { Helmet } from "react-helmet";
import { appService } from "/app/src/services";
import { Row } from "antd";

import App from "./app";
import Controls from "./_controls";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";

/**
 * Retrieves all apps and displays them in a list.
 */
export default function AppList() {
  const { t } = useTranslation();

  const appsQuery = useQuery({
    queryKey: ["apps"],
    queryFn: () =>
      appService.getAll(buildParams({ status: "[not]0", orderby: "name" })),
    initialData: { apps: [] },
  });

  return (
    <div className="apps">
      <Helmet>
        <title>{t("translation:apps")} - ItemPath</title>
      </Helmet>

      <Controls />
      <Row gutter={[24, 24]}>
        {appsQuery.data.apps.map((app) => (
          <App app={app} key={app.id} />
        ))}
      </Row>
    </div>
  );
}
