import React, { useCallback, useState } from "react";
import Table from "/app/src/components/generic/tables/table";
import ConvertToLocalTimezone, {
  PowerPickTimeZoneToLocal,
} from "/app/src/components/generic/formatting/dateTime";
import _ from "lodash";

import { reportRowService, reportService } from "/app/src/services";
import { buildParams } from "/app/src/helpers/params";
import { useTranslation } from "react-i18next";
import { Report, ReportColumn } from "/app/src/models";
import { Sort as SortType } from "/app/src/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";

// Check for different formatting depending on data type
const Cell = ({
  value,
  type,
  baseTable,
}: {
  value: string;
  type: string;
  baseTable: string;
}) => {
  if (type === "DateTime") {
    if (["APICall", "Log", "Task"].includes(baseTable)) {
      return <ConvertToLocalTimezone date={value} />;
    }
    return <PowerPickTimeZoneToLocal date={value} />;
  } else {
    return <div>{value}</div>;
  }
};

export default function ReportTable({
  report,
  columns,
}: {
  report: Report;
  columns: ReportColumn[];
}) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useState<SortType>([
    { id: String(report.sortColumnId), desc: isTrueSet(report.sortDescending) },
  ]);
  const queryClient = useQueryClient();

  function formatSortData(sortData: SortType) {
    if (sortData[0]) {
      return {
        sortDirection: sortData[0].desc,
        sortColumnId: sortData[0].id,
      };
    } else {
      return {
        sortDirection: "clear",
      };
    }
  }

  const reportRowsQuery = useQuery({
    queryKey: ["reportRows", report.id, page, pageSize],
    queryFn: () =>
      reportRowService.getAll(
        report.id,
        buildParams({ limit: pageSize, page }),
      ),
    enabled: columns.length > 0,
    initialData: { report_rows: [] },
    select: (data) => data.report_rows,
    keepPreviousData: true,
  });
  const reportRowsCountQuery = useQuery({
    queryKey: ["reportRowsCount", report.id],
    queryFn: () =>
      reportRowService.getAll(report.id, buildParams({ countOnly: true })),
    enabled: columns.length > 0,
    initialData: { count: 0 },
    select: (data) => data.count,
  });
  const { mutateAsync: updateReport } = useMutation({
    mutationFn: (data: {
      [key: string]: string | number | boolean | Date | undefined;
    }) => reportService.updateSingle(report.id, data).then(handlePromiseError),
    onSuccess: (data) => {
      queryClient.setQueryData(["report", report.id], data);
      reportRowsQuery.refetch();
    },
  });

  const updateSort = useCallback(
    (sortValue: SortType) => {
      setSort(sortValue);
      updateReport(formatSortData(sortValue));
    },
    [updateReport],
  );

  return (
    <Table
      rows={reportRowsQuery.data}
      tableColumns={formatColumns(columns)}
      length={reportRowsCountQuery.data}
      sort={sort}
      setSort={updateSort}
      paginationEnabled={{
        currentPage: page,
        pageSize,
        setPage,
        setPageSize,
      }}
      emptyText={t("translation:no_rows")}
      loading={reportRowsQuery.isFetching || reportRowsCountQuery.isFetching}
    />
  );
}

function formatColumns(columns: ReportColumn[]) {
  // Create React Table column data
  columns.sort((a, b) => {
    if (a?.position && b?.position) {
      return Number(a.position) > Number(b.position) ? 1 : -1;
    } else {
      return 0;
    }
  });

  const formatted = _.chain(columns)
    .map((column, key) => {
      let accessor = ";";
      if (column?.id) {
        accessor = column.id.toString();
      }
      return {
        Header: column.name,
        accessor: accessor,
        Cell: ({ cell: { value } }) => {
          return (
            <Cell
              value={value}
              type={column.filterType}
              baseTable={column.baseTable}
            />
          );
        },
      };
    })
    .value();
  return formatted;
}

function isTrueSet(value: string | undefined) {
  const isTrueSet = value === "True";
  return isTrueSet;
}
