import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { workflowService } from "/app/src/services";
import NewWorkflowForm from "./newWorkflowForm";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import Filters from "./filters";
import Controls from "./controls";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import getOrderByQuery from "/app/src/helpers/table";
import Table from "/app/src/components/generic/tables/table";
import { useTranslation } from "react-i18next";
import { useSort } from "/app/src/hooks";
import { Column } from "react-table";
import { Workflow as WorkflowType } from "/app/src/models";
export default function WorkflowList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns: Column<WorkflowType>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:name"),
        accessor: "name",
      },
      {
        Header: t("translation:last_triggered"),
        accessor: "lastTriggered",
        Cell: ({ cell: { value } }) => {
          return <DateTime date={value} format={"MMMM Do YYYY, h:mm:ss a"} />;
        },
      },
      {
        Header: t("translation:running"),
        accessor: "status",
        Cell: ({ value }) => {
          return value === 1 ? (
            <CheckOutlined
              style={{ color: "green", fontSize: "20px", paddingLeft: "15px" }}
            />
          ) : (
            <CloseOutlined
              style={{ color: "red", fontSize: "20px", paddingLeft: "15px" }}
            />
          );
        },
      },
    ];
  }, [t]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([{ id: "name", desc: false }]);
  const [searchString, setSearchString] = useState("");

  const [activeNewWorkflow, setActiveNewWorkflow] = useState(false);
  const toggleNewWorkflow = () => {
    setActiveNewWorkflow(!activeNewWorkflow);
  };

  const goToWorkflow = (id: number | undefined) => {
    navigate(`/workflows/${id}`);
  };

  const { data: workflows, isFetching } = useQuery({
    queryKey: ["workflows", page, pageSize, searchString, sort],
    queryFn: () => {
      return workflowService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          search: searchString,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { workflows: [] },
    select: (data: { workflows: WorkflowType[] }) => {
      return data.workflows;
    },
  });

  const { data: workflowsCount } = useQuery({
    queryKey: ["workflowsCount", searchString],
    queryFn: () => {
      return workflowService.getCount(buildParams({ search: searchString }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="workflows">
      <Helmet>
        <title>{t("translation:workflows")} - ItemPath</title>
      </Helmet>

      <Controls
        toggleNewWorkflow={toggleNewWorkflow}
        activeNewWorkflow={activeNewWorkflow}
      />
      {activeNewWorkflow ? <NewWorkflowForm /> : ""}
      <Row gutter={20}>
        <Col span={6}>
          <Filters setSearchString={setSearchString} />
        </Col>
        <Col span={18}>
          <Table
            loading={isFetching}
            rows={workflows}
            tableColumns={columns}
            length={workflowsCount}
            sort={sort}
            setSort={setSort}
            paginationEnabled={{
              currentPage: page,
              pageSize: pageSize,
              setPage: setPage,
              setPageSize: setPageSize,
            }}
            rowClicked={goToWorkflow}
            emptyText={t("translation:no_workflows")}
          />
        </Col>
      </Row>
    </div>
  );
}
