import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { taskService } from "/app/src/services";
import { Task as TaskType, Integration, Setting } from "/app/src/models";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import Table from "/app/src/components/generic/tables/table";
import Task from "./task";
import { useSort } from "/app/src/hooks";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { Column } from "react-table";

export default function Logs({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSort([]);
  const columns: Column<TaskType>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:description"),
        accessor: "data",
      },
      {
        Header: t("translation:status"),
        accessor: "status",
      },
      {
        Header: t("translation:date"),
        accessor: "creationDate",
        Cell: ({ cell: { value } }) => {
          return <DateTime date={value} />;
        },
      },
    ];
  }, [t]);

  const goToTask = (taskId: number) => {
    navigate(
      `/apps/${integration.appId}/integrations/${integration.id}/tasks/${taskId}`,
    );
  };

  const { data: tasksCount } = useQuery({
    queryKey: ["tasksCount", integration.id],
    queryFn: () => {
      return taskService.getCount(
        buildParams({ integrationId: integration.id }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  const { data: tasks, isFetching } = useQuery({
    queryKey: ["tasks", page, pageSize, sort, integration.id],
    queryFn: () => {
      return taskService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          integrationId: integration.id,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { tasks: [] },
    select: (data: { tasks: TaskType[] }) => {
      return data.tasks;
    },
  });

  return (
    <Routes>
      <Route path=":taskId/*" element={<Task integration={integration} />} />
      <Route
        path="/"
        element={
          <div className="tasks">
            <div className="box">
              <h1>{t("translation:logs")}</h1>
            </div>
            <Table
              loading={isFetching}
              rows={tasks}
              tableColumns={columns}
              length={tasksCount}
              sort={sort}
              setSort={setSort}
              paginationEnabled={{
                currentPage: page,
                pageSize: pageSize,
                setPage: setPage,
                setPageSize: setPageSize,
              }}
              rowClicked={goToTask}
              emptyText={t("translation:no_logs_found")}
            />
          </div>
        }
      />
    </Routes>
  );
}

export function Log({ setting }: { setting: Setting }) {
  return <div>{setting.name}</div>;
}
