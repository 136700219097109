import React from "react";
import getOrderByQuery from "/app/src/helpers/table";

import { taskService } from "/app/src/services";
import Table from "/app/src/components/generic/tables/table";
import DateTime from "/app/src/components/generic/formatting/dateTime";
import { Task } from "/app/src/models";
import { useSort } from "/app/src/hooks";
import { buildParams } from "/app/src/helpers/params";
import { Column } from "react-table";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

/**
 * Shows a table of tasks for a given integration
 * @param param0 Object containing integrationId, setTaskId and emptyText
 */
export default function TasksTable({
  integrationId,
  setTaskId,
  emptyText,
}: {
  integrationId: number;
  setTaskId: (taskId: number) => void;
  emptyText: string;
}) {
  const { t } = useTranslation();
  const columns: Column<Task>[] = React.useMemo(() => {
    return [
      {
        Header: t("translation:time"),
        accessor: "creationDate",
        Cell: ({ cell: { value } }) => {
          return <DateTime date={value} />;
        },
      },
    ];
  }, [t]);

  const [sort, setSort] = useSort([]);

  const { data: tasks, isFetching } = useQuery({
    queryKey: ["tasks", integrationId, sort],
    queryFn: () => {
      return taskService.getAll(
        buildParams({
          limit: 50,
          integrationId,
          orderby: getOrderByQuery(sort),
        }),
      );
    },
    initialData: { tasks: [] },
    select: (data: { tasks: Task[] }) => {
      return data.tasks;
    },
  });

  const { data: tasksCount } = useQuery({
    queryKey: ["tasksCount", integrationId],
    queryFn: () => {
      return taskService.getCount(
        buildParams({
          integrationId,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="workflow">
      <Table
        loading={isFetching}
        rows={tasks}
        tableColumns={columns}
        length={tasksCount}
        sort={sort}
        setSort={setSort}
        rowClicked={(id: string | number) => {
          setTaskId(Number(id));
        }}
        emptyText={emptyText}
      />
    </div>
  );
}
