import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton, Spin } from "antd";
import { Sort as SortType } from "/app/src/types";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  ColumnDef,
  ColumnSort,
} from "@tanstack/react-table";
import Pagination from "./pagination";

/**
 * Empty table component
 * @param emptyText
 */
export function TableEmpty({ emptyText }: { emptyText: string }) {
  return (
    <div className="reportLoading text-center">
      {emptyText && <span className="loadingText">{emptyText}</span>}
    </div>
  );
}

/**
 * Table component that uses React-Table v8
 * @param rows
 * @param tableColumns
 * @param length
 * @param sort
 * @param setSort
 * @param rowClicked
 * @param emptyText
 * @param enableRowSelection
 * @param paginationEnabled
 * @param loading
 * @param classStyle
 * @returns React-Table v8 table
 */
export default function TableUpgrade({
  rows,
  tableColumns,
  length,
  sort,
  setSort,
  rowClicked,
  emptyText,
  enableRowSelection = false,
  paginationEnabled,
  loading = false,
  classStyle = "dataTable",
}: {
  rows: any[];
  tableColumns: ColumnDef<any>[];
  length: number;
  sort: ColumnSort[];
  setSort: React.Dispatch<React.SetStateAction<SortType>>;
  rowClicked?: (key: number) => void;
  emptyText: string;
  loading?: boolean;
  enableRowSelection?: boolean;
  paginationEnabled?: {
    setPageSize: (pageSize: number) => void;
    setPage: (page: number) => void;
    currentPage: number;
    pageSize: number;
  };
  classStyle?: string;
}) {
  const data = useMemo(() => rows, [rows]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const { t } = useTranslation();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sort,
    },
    onSortingChange: setSort,
    enableSorting: !loading,
    manualSorting: true,
    enableRowSelection,
    enableColumnResizing: true,
  });

  const onSubmitHandler = useCallback(
    (rowId: number) => () => {
      rowClicked(rowId);
    },
    [rowClicked],
  );

  return (
    <>
      <div className={classStyle}>
        {length === null || loading ? (
          <>
            <table className="table table-striped table-bordered">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getIsSorted() === "asc"
                          ? "🔼"
                          : header.column.getIsSorted() === "desc"
                          ? "🔽"
                          : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
            </table>
            <Spin>
              <Skeleton title={false} paragraph={{ rows: 14, width: 100 }} />
            </Spin>
          </>
        ) : length === 0 ? (
          <TableEmpty emptyText={emptyText} />
        ) : tableColumns.length === 0 ? (
          <TableEmpty emptyText={t("translation:no_columns_selected")} />
        ) : (
          <table className="table table-striped table-bordered">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getIsSorted() === "asc"
                        ? "🔼"
                        : header.column.getIsSorted() === "desc"
                        ? "🔽"
                        : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  className="rowHover"
                  key={row.original.id}
                  onClick={onSubmitHandler(row.original.id)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {paginationEnabled && (
        <Pagination
          length={length}
          pageSize={paginationEnabled.pageSize}
          setPageSize={paginationEnabled.setPageSize}
          currentPage={paginationEnabled.currentPage}
          setPage={paginationEnabled.setPage}
        />
      )}
    </>
  );
}
