import { string, object } from "yup";
import i18next from "/app/src/locales/config";

//regex where the string can contain any valid URL character
const urlValidation = /^[!#$&-;=?-\][_a-z~ ]+$/;

export const connectionPathSchema = object().shape({
  value: string()
    .matches(urlValidation, i18next.t("translation:invalid_url_character"))
    .trim(),
});
