import { makeAPIRequest } from "../helpers/apiCall";
import { SnapshotData } from "../models";

function getAll(
  id: number | string,
  params: URLSearchParams = null,
): Promise<{ snapshot: SnapshotData }> {
  return makeAPIRequest({
    url: `/snapshots/${id}/data`,
    params,
    method: "get",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
export const snapshotDataService = {
  getAll,
};
