import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
  auth: {
    tenantId: process.env.REACT_APP_AZURE_OAUTH_TENANCY, // Your tenant ID goes here
    clientId: process.env.REACT_APP_AZURE_OAUTH_APPLICATION_ID, // Your client (application) ID goes here
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_OAUTH_TENANCY}`, // Replace the last part with your tenant ID
    redirectUri: `${process.env.REACT_APP_URL}/login`, // Must be the same in Azure AD portal, can be replace with an environment variable: process.env.REACT_APP_REDIRECT_URL
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.info(message);
            return;
        }
      },
    },
  },
};
